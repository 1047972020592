

const constantUtils = require('./constantUtils');

const WidgetDataDisplayTypes = {
    PERCENT: 'PERCENT',
    CURRENCY: 'CURRENCY',
    CURRENCY_NO_CENT: 'CURRENCY_NO_CENT',
    PERCENTILE: 'PERCENTILE',
    PROGRESS_BAR: 'PROGRESS_BAR'
};

const properties = {};

constantUtils.configure({ constants: WidgetDataDisplayTypes, properties });

module.exports = WidgetDataDisplayTypes;
