import landingTpl from './landing.tpl.html';
import landingCtrl from './landing.controller';

landingConfig.$inject = ['$stateProvider'];

export default function landingConfig($stateProvider) {
    $stateProvider.state('landing', {
        url: '/landing/:programType/:focus?code&perm&messageRecipientID&participant',
        reloadOnSearch: false,
        parent: 'auth',
        views: {
            // Whenever a state change happens with options { reload: true }, all of these controllers run again.
            'main@': {
                templateUrl: landingTpl,
                controller: landingCtrl,
                controllerAs: 'vm',
            },
        },
        data: {
            pageTitle: 'Landing',
            permission: null,
        },
        params: {
            participant: {
                value: 'allParticipants'
            },
        },
        resolve: {
            LandingSetup: ['LandingService', '$stateParams', function(LandingService, $stateParams) {
                let startDate, endDate;
                if ($stateParams.programType.toLowerCase() === 'anniversary') {
                    startDate = '1900-01-01';
                    const tomorrowDate = new Date();
                    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
                    endDate = tomorrowDate;
                }
                return LandingService.getLandingSetup($stateParams.programType, $stateParams.focus, $stateParams.participant, startDate, endDate);
            }],
            Content: ['$stateParams', 'ContentService', function($stateParams, ContentService) {
                const contentCode = $stateParams.code || `landing-${$stateParams.programType}-${$stateParams.focus}`;
                const permissionGroup = $stateParams.perm;
                return ContentService.getContentByCode(contentCode, permissionGroup)
                    .catch(function redirectIfFoundButNotPublic(err) {
                        if (err.data.returnCode === 6031) {
                            // When a content page exists but isn't public and the user isn't logged in,
                            // we want to redirect them to the login page, then try going to the page again
                            // after they've logged in.
                            err.redirectTo = 'login';
                            err.redirectParams = {
                                redirectTo: 'content',
                                redirectParams: $stateParams
                            };
                            err.disableDefaultHandling = true;
                        }
                        throw err;
                    });
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                // Since nav seems to be the parent of all other states, it's probably a good place to load app-wide phrases
                return RnTranslationService.loadPhrases('app');
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('content');
            }]
        }
    });
}
