incentiveWidgetCtrl.$inject = [];

export default function incentiveWidgetCtrl() {
    this.$onInit = () => {
        const vm = this;
        vm.active = 0;

        vm.noIncentiveImage = './assets/shared/landing/anniversary/incentives-widget-background-empty.jpg';
        if (vm.incentiveType === 'game') {
            vm.noIncentiveImage = './assets/shared/landing/anniversary/games-widget-background-empty.jpg';
        }
    };
}
