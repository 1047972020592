import moment from 'moment';

anniversaryWidgetCtrl.$inject = ['$translate'];

export default function anniversaryWidgetCtrl($translate) {
    this.$onInit = () => {
        const vm = this;
        vm.active = 0;
        const filteredAnniversaries = vm.anniversaries.filter(anniversary => !['', null, undefined].includes(anniversary.startedOn));
        if (filteredAnniversaries.length > 0) {
            vm.anniversaries = filteredAnniversaries.map((anniversary) => {
                anniversary.date = moment(anniversary.sentDate).format('MMM DD');
                const years = moment(anniversary.sentDate).diff(moment(anniversary.startedOn), 'years');
                // TODO: Customize the code for client based images for anniversaries certificates
                if ([3, 4].includes(years)) {
                    anniversary.imageURL = './assets/shared/landing/anniversary/3-year.jpg';
                    anniversary.years = 3;
                } else if ([5, 6, 7, 8, 9].includes(years)) {
                    anniversary.imageURL = './assets/shared/landing/anniversary/5-year.jpg';
                    anniversary.years = 5;
                } else if (years >= 10) {
                    anniversary.imageURL = './assets/shared/landing/anniversary/10-year.jpg';
                    anniversary.years = 10;
                } else {
                    anniversary.imageURL = './assets/shared/landing/anniversary/1-year.jpg';
                    anniversary.years = 1;
                }

                // defining year pluralization
                anniversary.yearText = anniversary.years > 1
                    ? $translate.instant('content_LANDING_ANNIVERSARY_WIDGET_CAPTION_YEARS', { number: anniversary.years })
                        : $translate.instant('content_LANDING_ANNIVERSARY_WIDGET_CAPTION_YEAR', { number: anniversary.years });
                return anniversary;
            });
        } else {
            vm.anniversaries = [];
        }
    };
}
