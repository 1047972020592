LandingService.$inject = ['$http', 'Session', 'SpotlightService'];

export default function LandingService($http, Session, SpotlightService) {

    async function getLandingSetup(programType, focus, participant, startDate, endDate) {
        const user = Session.getUser();
        const company = Session.getCompany();
        let params = {};

        if (startDate && endDate) {
            params = {
                startDate,
                endDate
            };
        }
        const { data } = await $http.get(`/api/landing/${programType}/${focus}/setup`, { params });


        let landingSetup = {};

        if (programType === 'recognition') {
            const recentApplauses = await SpotlightService.getSpotlightItems({
                participant,
                recognition: 'applause|cultureStory',
                sent: undefined,
                recentDays: undefined,
                startDate: undefined,
                contactID: user.userID,
                spotlightItemsToShow: 10
            });
            landingSetup = {
                dashboard: {
                    currentEarningsToDate: data.pointsEarned,
                    currentBalance: user.points,
                    lastMonthApplauses: {
                        received: data.applausesReceived,
                        sent: data.applausesSent
                    },
                    lastMonthNominations: {
                        received: data.nominationsReceived,
                        sent: data.nominationsMade
                    },
                    lastMonthPointsAwarded: data.pointsAwarded,
                    teamApplauses: {
                        received: data.teamApplausesReceived,
                        sent: data.teamApplausesSent
                    },
                    teamNominations: {
                        received: data.teamNominationsReceived,
                        sent: data.teamNominationsMade
                    },
                },
                recentApplauses
            };
        } else if (programType === 'anniversary') {
            const recentApplauses = await SpotlightService.getSpotlightItems({
                participant,
                recognition: 'applause|cultureStory',
                sent: undefined,
                recentDays: undefined,
                startDate: undefined,
                contactID: user.userID,
                spotlightItemsToShow: 10
            });
            const recentAnniversaries = await SpotlightService.getSpotlightMarqueeItems({
                participant,
                recognition: 'anniversary',
                sent: undefined,
            });
            const myIncentives = await $http.get('api/promo/marquee-list', {
                params: {
                    promoGroupID: company.incentiveGroups.filter(group => group.name === 'Incentives')[0].id
                }
            });
            const myGames = await $http.get('api/promo/marquee-list', {
                params: {
                    promoGroupID: company.incentiveGroups.filter(group => group.name === 'Games')[0].id
                }
            });
            landingSetup = {
                dashboard: {
                    currentBalance: user.points,
                    lastMonthPointsAwarded: data.pointsAwarded,
                    lastMonthApplauses: {
                        received: data.applausesReceived,
                        sent: data.applausesSent
                    },
                    teamApplauses: {
                        received: data.teamApplausesReceived,
                        sent: data.teamApplausesSent
                    },
                },
                recentApplauses,
                recentAnniversaries,
                myIncentives: myIncentives.data,
                myGames: myGames.data,
            };
        }

        return landingSetup;
    }

    return {
        getLandingSetup
    };
}
