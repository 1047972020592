import { a } from "bowser";

ReloadableCardService.$inject = ['$http', 'Session'];

export default function ReloadableCardService($http, Session) {

    const user = Session.getUser();
    const spectator = Session.getSpectator();
    async function getStatement({ skip } = {}) {
        // right now tutuka is the only reloadable card we make a webservice call to
        const res = await $http.get('/api/tutuka/statement', {
            params: {
                skip
            }
        });
        return res.data;
    }

    async function getSetup() {
        const res = await $http.get(`/api/reloadable/${user.userID}/get-setup`);
        return res.data;
    }

    async function createRedemption({ isNewCard, storeID, reloadableSetupID, amount, address }) {
        let url = '/api/reloadable/create-reload-redemption';
        let companyName = null;
        let address1 = null;
        let address2 = null;
        let city = null;
        let province = null;
        let zip = null;
        let country = null;
        if (isNewCard) {
            url = '/api/reloadable/create-new-redemption';
            companyName = address.companyName;
            address1 = address.address1;
            address2 = address.address2;
            city = address.city;
            province = address.province;
            zip = address.zip;
            country = address.country;
        }
        let redeemedBy_ContactID = user.userID;
        if (spectator) {
            redeemedBy_ContactID = spectator.userID;
        }
        const res = await $http.post(url, {
            storeID,
            reloadableSetupID,
            redeemedBy_ContactID,
            contactID: user.userID,
            companyName,
            amount,
            address1,
            address2,
            city,
            state: province,
            zip: zip,
            country: country,
        });
        return res.data;
    }

    return {
        getStatement,
        getSetup,
        createRedemption,
    };
}
