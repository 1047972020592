import angular from 'angular';

import '../../../core/core.module';

import incentiveWidget from './incentiveWidget.component';

export default angular.module('app.landing.incentiveLandingWidget', [
    'app.core'
])
.component('incentiveLandingWidget', incentiveWidget);
