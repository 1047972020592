import incentiveWidgetTpl from './incentiveWidget.tpl.html';
import incentiveWidgetCtrl from './incentiveWidget.controller';

export default {
    templateUrl: incentiveWidgetTpl,
    controller: incentiveWidgetCtrl,
    controllerAs: 'vm',
    bindings: {
        incentives: '<',
        incentiveType: '<',
    }
};
