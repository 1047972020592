spotlightCtrl.$inject = [
    '$rootScope', '$stateParams', 'marqueeItems', 'contact', 'MessageService', 'anniversary', 'Session'
];

export default function spotlightCtrl(
    $rootScope, $stateParams, marqueeItems, contact, MessageService, anniversary, Session
) {
    const vm = this;

    MessageService.openFromUrl();

    vm.anniversary = anniversary;

    const user = Session.getUser();
    vm.contact = contact;
    const isSelf = vm.contact && (vm.contact.userID === user.userID);

    vm.marqueeItems = marqueeItems;

    vm.selectedRecognitionFilter = $stateParams.recognition;

    vm.isShowBirthdays = Number($stateParams.isShowBirthdays) === 1;
    vm.isShowAnniversary = Number($stateParams.isShowAnniversary) === 1;
    vm.isHideMarquee = Number($stateParams.isHideMarquee);
    vm.showBannerButtons = $stateParams.buttons;
    vm.showBanners = ((vm.contact && isSelf) || !vm.contact) && $stateParams.code !== 'contact';
}
