import anniversaryTabTpl from './anniversaryTab.tpl.html';
import anniversaryTabCtrl from './anniversaryTab.controller';

export default {
    templateUrl: anniversaryTabTpl,
    controller: anniversaryTabCtrl,
    controllerAs: 'vm',
    bindings: {
        landingSetup: '<',
        content: '<',
        program: '<',
        role: '<',
        code: '<',
        participant: '<',
    }
};
