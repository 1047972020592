import cardsTpl from './cards.tpl.html';
import cardsCtrl from './cards.controller';

cardsConfig.$inject = ['$stateProvider'];

export default function cardsConfig($stateProvider) {
    $stateProvider.state('reloadableCard', {
        parent: 'auth',
        url: '/reloadable-card?tab',
        data: {
            pageTitle: 'Reloadable Cards',
        },
        params: {
            tab: {
                value: 'description',
                dynamic: true
            }
        },
        views: {
            'main@': {
                controller: cardsCtrl,
                controllerAs: 'vm',
                templateUrl: cardsTpl,
            },
        },
        resolve: {
            cardSetup: ['ReloadableCardService', function(ReloadableCardService) {
                return ReloadableCardService.getSetup();
            }],
            translation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('reloadableCard');
            }],
            rewardTranslation: ['RnTranslationService', function(RnTranslationService) {
                return RnTranslationService.loadPhrases('reward');
            }],
        }
    });
}
