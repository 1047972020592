import cardsCtrl from "./cards.controller";
import cardsTpl from "./cards.tpl.html";

export default {
    templateUrl: cardsTpl,
    controller: cardsCtrl,
    controllerAs: 'vm',
    bindings: {
        cardSetup: '<'
    }
};
