import angular from 'angular';

import '../../../core/core.module';

import anniversaryWidget from './anniversaryWidget.component';

export default angular.module('app.landing.anniversaryLandingWidget', [
    'app.core'
])
.component('anniversaryLandingWidget', anniversaryWidget);
